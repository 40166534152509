import React from "react";

import Layout from "../../components/layout";
import { Seo } from "../../components";

import image1 from "../../images/about/image1.jpg";
import image2 from "../../images/about/image2.jpg";
import image3 from "../../images/about/image3.jpg";
import image4 from "../../images/about/image4.jpg";
import image5 from "../../images/about/image5.jpg";
import image6 from "../../images/about/image6.jpg";
import image7 from "../../images/about/image7.jpg";
import image8 from "../../images/about/image8.jpg";
import image9 from "../../images/about/image9.jpg";
import image10 from "../../images/about/image10.jpg";
import image11 from "../../images/about/image11.jpg";
import image12 from "../../images/about/image12.jpg";

const FormerBishops = ({ location }) => {
  return (
    <Layout location={location}>
      <Seo title="Former Bishops" />
      <div className="about">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h3 className="about-subtitle">Former Bishops</h3>
              <div className="row">
                <div className="col-12 col-md-3 mb-2">
                  <img src={image3} className="w-100" alt="image3"></img>
                  <p>Rt. Rev. J. M. Speechly [1879 – 1889]</p>
                </div>
                <div className="col-12 col-md-3 mb-2">
                  <img src={image4} className="w-100" alt="image4"></img>
                  <p>Rt. Rev. A. N. Hodges [1890 – 1904]</p>
                </div>
                <div className="col-12 col-md-3 mb-2">
                  <img src={image5} className="w-100" alt="image5"></img>
                  <p>Rt. Rev. Dr. C. H. Gill [1905 – 1924]</p>
                </div>
                <div className="col-12 col-md-3 mb-2">
                  <img src={image6} className="w-100" alt="image6"></img>
                  <p>Rt. Rev. E. A. L. Moore [1925 – 1937]</p>
                </div>
                <div className="col-12 col-md-3 mb-2">
                  <img src={image7} className="w-100" alt="image7"></img>
                  <p>Rt. Rev. B. C. Corfield [1938 – 1944]</p>
                </div>
                <div className="col-12 col-md-3 mb-2">
                  <img src={image8} className="w-100" alt="image8"></img>
                  <p>Rt. Rev. C. K. Jacob (1947–1957)</p>
                </div>
                <div className="col-12 col-md-3 mb-2">
                  <img src={image9} className="w-100" alt="image9"></img>
                  <p>Rt. Rev. M. M. John (1958–1974)</p>
                </div>
                <div className="col-12 col-md-3 mb-2">
                  <img src={image10} className="w-100" alt="image10"></img>
                  <p>Rt. Rev. &nbsp;T. S. Joseph (1974–1981) </p>
                </div>
                <div className="col-12 col-md-3 mb-2">
                  <img src={image11} className="w-100" alt="image11"></img>
                  <p>Rt. Rev. M. C. Mani (1981–1993)</p>
                </div>
                <div className="col-12 col-md-3 mb-2">
                  <img src={image12} className="w-100" alt="image12"></img>
                  <p>Rt. Rev. Dr Sam Mathew (1993–2001)</p>
                </div>
                <div className="col-12 col-md-3 mb-2">
                  <img src={image1} className="w-100" alt="image1"></img>
                  <p>Rt. Rev. Thomas Samuel (2001–2011)</p>
                </div>
                <div className="col-12 col-md-3 mb-2">
                  <img src={image2} className="w-100" alt="image2"></img>
                  <p>Rt. Rev. Thomas K Oommen (2011–2020)</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default FormerBishops;
